import "./index.scss";
import "./Animations/underlineText.scss";
import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from 'react-dom/client'
import React from 'react'
import {BrowserRouter} from 'react-router-dom'

import { AuthProvider } from './context/authContext'
import { ParallaxProvider } from "react-scroll-parallax";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './App'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <AuthProvider>
        <Provider store={store}>
          <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined // optional, default undefined
            }}
            // language="[optional_language]"
            // useRecaptchaNet="[optional_boolean_value]"
            // useEnterprise="[optional_boolean_value]"
            // container={{ // optional to render inside custom element
            //   element: "[required_id_or_htmlelement]",
            //   parameters: {
            //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
            //     theme: 'dark', // optional, default undefined
            //   }
            // }}
          >
            <ParallaxProvider>
              <App />
            </ParallaxProvider>
          </GoogleReCaptchaProvider>
        </Provider>
      </AuthProvider>    
    </BrowserRouter>
  </React.StrictMode>,
)
