import './Settings.scss'
import React from 'react'
import PasswordForgot from '../../../../Apps/AccountApp/routes/PasswordForgot'
import PasswordUpdate from '../../../../Apps/AccountApp/routes/PasswordUpdate'
import FileUpload from '../../../FileUpload'
import UserProfile from '../../../../Apps/AccountApp/routes/UserProfile'

export default function Settings({
    loading = true,
    setLoading = (f)=>f,
    values = {},
    setValues = (f)=>f,
    userUpdate = (f)=>f,
}) {

    
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        userUpdate({variables: {input: values}});
        setLoading(false);
    };
    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value})
    };

  return (
    <div className='Settings'>

    <FileUpload 
        values={values}
        loading={loading}
        setValues={setValues}
        setLoading={setLoading}    
    />

    {values &&
        <UserProfile 
            {...values}
            loading={loading}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
        />
    }
    <PasswordForgot /> 
    <PasswordUpdate />

    </div>
  )
}
