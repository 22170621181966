import React, {useState, useMemo} from 'react'
import {toast} from 'react-toastify'
import { useQuery, useMutation } from '@apollo/client';
import { USER_UPDATE } from '../../../../graphql/mutations'
import { PROFILE, GET_ALL_USERS } from '../../../../graphql/queries'
// import { AuthContext } from '../../context/authContext'

import Dashboard1 from '../../../../components/dashboards/Dashboard1/Dashboard1';

export default function Dashboard() {
    // const {state} = useContext(AuthContext);
    const [values, setValues] = useState({
        username: '',
        name: '',
        email: '',
        about: '',
        images: []
    })
    const [loading, setLoading] = useState(false);

    const {data: usersData , loading: usersLoading, error } = useQuery(GET_ALL_USERS);

    const {data : profileData, refetch} = useQuery(PROFILE);
    useMemo(()=>{
        refetch()
        if(profileData){
            setValues({
                ...values,
                username: profileData.profile.username,
                name: profileData.profile.name,
                email: profileData.profile.email,
                about: profileData.profile.about,
                images: profileData.profile.images
            })
        }
    }, [profileData]);

    // Mutation
    const [userUpdate] = useMutation(USER_UPDATE, {
        update: ({profileData}) => {
            console.log('USER UPDATE MUTATION IN PROFILE', profileData);
            toast.success('Profile updated');
            // console.log(...profileData)
            // setValues(...profileData.profile)
        }
    });
    
return (
    <Dashboard1 
        usersData = {usersData?.allUsers}
        usersLoading = {usersLoading}
        loading = {loading}
        setLoading = {setLoading}
        values = {values}
        setValues = {setValues}
        userUpdate = {userUpdate}
    />
);
}
