import React, { useContext, useEffect, useMemo, useState } from "react";
import "./MenuButton.scss";
import { NavLink, useNavigate } from "react-router-dom";

import { AuthContext } from "../../../context/authContext";
import { auth } from "../../../utils/firebase";

import SocialButtons from "../../buttons/SocialButtons/SocialButtons";
import { AppsData } from "../../../App";
import { PROFILE } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import Badge1 from "../../badges/Badge1/Badge1";

const noLinkRoutes = ["","/",
    "/login",
    "/dashboard",
    "/account",

    "/complete-registration",
    
    "/update/:postid",
    "/update",
    "/singlepost",
    "/singleuser",
]

export default function MenuButton({appData}) {
    const {state, dispatch} = useContext(AuthContext)
    let navigate = useNavigate();
    const { user } = state;

    const logout = () => {
        auth.signOut();
        dispatch({
            type: 'LOGGED_IN_USER',
            payload: null
        });
        navigate('/login')
    }

    const [userValues, setUserValues] = useState({
        username: '',
        name: '',
        email: '',
        about: '',
        images: []
    })
    const {data, refetch} = useQuery(PROFILE);
    useMemo(()=>{
        refetch()
        if(data){
            setUserValues({
                ...userValues,
                username: data.profile.username,
                name: data.profile.name,
                email: data.profile.email,
                about: data.profile.about,
                images: data.profile.images
            })
        }
    }, [data]);

    const [checked, setChecked] = useState(false);
    const [innerWidth, setInnerWidth] = useState(0);
    const [routesLinksData, setRoutesLinksData] = useState(routesLinksFromAppData(appData));
    const [appsLinksData, setAppsLinksData] = useState(appsLinksFromAppData(appData));

    const inputElement = React.useRef();

    React.useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);
    
    React.useEffect(() => {
        setRoutesLinksData(routesLinksFromAppData(appData))
        setAppsLinksData(appsLinksFromAppData(appData))
    }, [appData, user]);
    
    function handleResize() {
        if(innerWidth !== window.innerWidth){
            if(window.innerWidth > 1099){
                setChecked(true);
            }else{
                setChecked(false);
            }
        }
        setInnerWidth(window.innerWidth);
    }
    function appsLinksFromAppData(appData) {

        return appData && AppsData?.map(_appData =>{
            if(_appData.type === "public" && user) return;
            if(_appData.type === "private" && !user) return;
            if(_appData.path === appData.path) return;
            
            let OK = true;
            noLinkRoutes.map((noLinkRoute) => {
                if(noLinkRoute === _appData.path) OK=false;
            })
            if(!OK) return;

            return {
                to: _appData.path,
                label: _appData.label
            }
        }).filter(n => n);
    }
    function routesLinksFromAppData(appData) {

        return appData?.routesData.map(routeData =>{
            if(routeData.type === "public" && user) return;
            if(routeData.type === "private" && !user) return;
    
            let OK = true;
            noLinkRoutes.map((noLinkRoute) => {
                if(noLinkRoute === routeData.path){
                     OK=false
                };
            })
            if(!OK) return;

            return {
                to: `${appData.path}${routeData.path}`,
                label: routeData.title
            }
        }).filter(n => n);
    }
    function handlLabelClick() {
        setChecked(!checked);
    }
    function handleLinkClick() {
        if(window.innerWidth < 961){
            setChecked(false);
        }
    }

    return (
        <div className="MenuButton">
            <input ref={inputElement}
                className="menu-icon"
                type="checkbox"
                id="menu-icon"
                name="menu-icon"
                checked = {checked}
                onChange={(f)=>f}
            />
            <label onClick={handlLabelClick} htmlFor="menu-icon"></label>
            <nav className="nav gradient-border">
                <ul>
                {user && <li>
                    <NavLink className="profileNavLink" to={"/account/dashboard"} >
                        <Badge1 
                            name={userValues.name}
                            imageUrl={userValues?.images[0]?.url}
                        />
                        {userValues.name !== "" ? userValues.name : userValues.username}
                    </NavLink>
                </li>}
                {(routesLinksData?.length > 0) && <span>Routes</span>}
                    {routesLinksData?.map(link=>{
                        return link && <li key={link.to}>
                            <NavLink onClick={handleLinkClick} to={link.to} >{link.label}</NavLink>
                        </li>
                    })}
                    {(appsLinksData?.length > 0) && <span>Apps</span>}
                    {appsLinksData?.map(link=>{
                        return link && <li key={link.to}>
                            <NavLink onClick={handleLinkClick} to={link.to} >{link.label}</NavLink>
                        </li>
                    })}
                    <li>
                        <SocialButtons></SocialButtons>
                    </li>
                    <li>
                    {user && <>
                        <NavLink onClick={logout} to={"/account/login"}>Logout</NavLink>
                    </>}
                    {!user &&
                        <NavLink to={"/account/login"} >Login</NavLink>
                    }
                    </li>
                </ul>
            </nav>
        </div>
    );
}

