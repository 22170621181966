import React, { useRef } from 'react'
import { useLocation } from "react-router-dom";
import "./CV.scss";
import MyPDF from './jColacoCV.pdf';
import PictureFrame from '../../pictureFrames/PictureFrame/PictureFrame';

import img_linkedin from "../../../Images/icons/social/linkedin.png"
import img_github from "../../../Images/icons/social/github.png"
import img_codepen from "../../../Images/icons/social/codepen.png"
import img_arduino from "../../../Images/icons/arduino.png"
import underConstruction from "./../../../Images/underConstruction.png"
import ergofaceLogo from "./../../../Images/ergofaceLogo.png"
import churrascoLogo from "./../../../Images/churrascoLogo.png"

import img_agile from "./imgs/agile.png"

import cert_arduino from "./imgs/diplomas/cert_arduino.jpg"
import cert_csw from "./imgs/diplomas/cert_csw.jpg"
import cert_react from "./imgs/diplomas/cert_react.jpg"
import cert_udemy_gql_react from "./imgs/diplomas/cert_udemy_gql_react.jpg"
import cert_wa from "./imgs/diplomas/cert_wa.jpg"
import myPic from "./imgs/myPic.png"

const diplomasPictures = [
    {
        src: cert_arduino,
        label: "Arduino diploma"
    },
    {
        src: cert_csw,
        label: "Critical Software"
    },
    {
        src: cert_react,
        label: "React development"
    },
    {
        src: cert_udemy_gql_react,
        label: "MERN GraphQL development"
    },
    {
        src: cert_wa,
        label: "World Academy diploma"
    }
]

export default function CV() {
    const location = useLocation();
    
    const cvRef = useRef();
    const portfolioRef = useRef();
    const projectsRef = useRef();
    const pageRefs = [cvRef, portfolioRef, projectsRef]

    React.useEffect(() => {
        activateClick(location.hash);
    }, []);

    React.useEffect(() => {
        activateClick(location.hash);

        pageRefs.map(({current}) => {
            if(location.hash === `#${current.dataset.page}`)
                current.scrollIntoView({behavior: 'smooth'})
        });

    }, [location.hash]);
    
    const activateClick = (hash) => {
        let OK= false;

        pageRefs.map(({current}) => {
            current.classList.remove("active");
            if(hash === `#${current.dataset.page}`){
                current.classList.add("active");
                OK=true;
                return;
            }
        });

        if(!OK)cvRef.current.classList.add("active")
    }

    const navButtons = []
    // const navButtons = [
    //     { state: "active", title: pagesData[1].navLinks[0].label, ref: pagesData[1].navLinks[0].ref },
    //     { state: "", title: pagesData[1].navLinks[1].label, ref: pagesData[1].navLinks[1].ref },
    //     { state: "", title: pagesData[1].navLinks[2].label, ref: pagesData[1].navLinks[2].ref },
    // ];
    function handleLinkClick (e) {
        e.preventDefault();
        // location.pathname = `${currentTarget.href}`
        // navigate(`${e.currentTarget.href}`, { replace: true })
    }
    function handleClick_btn (hash) {
        // const   pages = document.querySelectorAll(".page"),
                // navLis = document.querySelectorAll(".navbar li");
        // const btnKey = currentTarget.dataset.key.toLowerCase();
        // for(let ele of navLis){
        //     ele.classList.remove("active");
        // }
        // for(let ele of pages){
        //     ele.classList.remove("active");
        // }
        // currentTarget.classList.add("active");
        // document.querySelector(`.page--${btnKey}`).classList.add("active");
    }

return (
    <div className="CV">
    <div className="site-wrapper">
    <div className="cv">
        <header className="cv__head">
            <section className="user">
                <section className="user-media">
                    <article className="user__media user__media--hero">
                        <div className="media">
                            <div className="media__wrapper">
                                <img className="media__image" 
                                    src={myPic} alt="João Colaço" 
                                />
                            </div>
                        </div>
                    </article>
                </section>
                <section className="user-title section ">
                    <h1 className="user__name name">
                        <span className="name__first-name">João</span>
                        <span className="name__last-name">Colaço</span>
                    </h1>
                    <h2 className="user__job">Web developer<br/><em>Frontend</em> expert<br/><em>Backend</em> connoisseur</h2>
                </section>
                <section className='downloadButton'>
                    <a download="jColaco_CV" href={MyPDF}>Download my PDF CV
                        <figure></figure>
                    </a>
                </section>
                <section className="user__contact section">
                        <h2 className="section__title--simple">Contact</h2>

                        <article className="contact">
                            <span className="contact__mail">
                                <a href="mailto:me@jColaco.dev">me@jColaco.dev</a>
                            </span>
                            <div className="contact__adress adress">
                                <span className="adress__city">Lisboa</span>
                                <br/>
                                <span className="adress__country">Portugal</span>
                            </div>
                        </article>
                    </section>
                <div className="user__metas">
                    <section className="user__social section">
                        <h2 className="section__title--simple">Social</h2>

                        <article className="user__socials socials">
                            <a className="social social--twitter" 
                                href="https://www.linkedin.com/in/jcolacodev/" target="_blank" rel="noopener noreferrer">
                                <img className="social__icon" src={img_linkedin} alt="" />
                                <span>linkedin.com/jColacoDev</span>
                            </a>
                            <a className="social social--codepen" href="https://github.com/jColacoDev" target="_blank" rel="noopener noreferrer">
                                <img className="social__icon" src={img_github} alt="" />
                                <span>github.com/jColacoDev</span>
                            </a>
                            <a className="social social--github" href="https://codepen.io/jcolacodev" target="_blank" rel="noopener noreferrer">
                                <img className="social__icon" src={img_codepen} alt="" />
                                <span>codepen.io/jColacoDev</span>
                            </a>
                            <a className="social social--github" href="https://forum.arduino.cc/u/jcolaco/" target="_blank" rel="noopener noreferrer">
                                <img className="social__icon" src={img_arduino} alt="" />
                                <span>arduino.com/jColaco</span>
                            </a>
                        </article>
                    </section>
                    <section className="section">
                        <h2 className="section__title--simple">Diplomas</h2>
                        <PictureFrame pictures={diplomasPictures} autoPlay={10000}/>
                    </section>
                </div>
            </section>
        </header>

        <main className="cv__main">
            <section className="section section--nav">
                <div className="navbar">
                    <ul className="nav">
                    {/* {navButtons.map((item) => 
                        <li key={item.title} 
                            id={`${item.title}--btn`}
                            data-key={item.title}
                            className={item.state}>
                            <Link onClick={handleLinkClick} to={item.ref} className='cv--link'>
                                <button id={item.ref.substring(1)} className="btn--cv">
                                    {item.title}
                                </button>
                            </Link>
                        </li>
                    )} */}
                    </ul>
                </div>
            </section>
            <div className="page active" data-page="cv" ref={cvRef}>

                <section className="section section--secondary section--summary">
                    <div className="section__body">
                        <article>
                            <h3>U<span className="smallCaps">I</span>/U<span className="smallCaps">X</span> D<span className="smallCaps">esign</span></h3>
                            <p>Figma, Sketch, Inkscape
                               <br />Interfaces, prototypes, style guides</p>
                        </article>
                        <article>
                            <h3>I<span className="smallCaps">ntegration</span></h3>
                            <p>Responsive & mobile-first, accessibility, SEO, performance, Firebase Authentication</p>
                        </article>
                        <article>
                            <h3>F<span className="smallCaps">rontend Development</span></h3>
                            <p>HTML5, CSS3, Sass, Javascript ES6, Typescript
                               <br/>React, Next, Native, Angular, Redux, DevTools, Apollo GraphQL
                               <br />Test Js: Jest, React: RTL, Angular: Testbed + Spectator
                            </p>
                        </article>
                        <article>
                            <h3>B<span className="smallCaps">ackend Development</span></h3>
                            <p>Node.Js, PhP, Java, Python
                                <br/>Express, GraphQL, MongoDB, PostgreSQL, MySQL, Redis
                            </p>
                        </article>
                        <article>
                            <h3>D<span className="smallCaps">evOps (CI/CD)</span></h3>
                            <p>Node npm, Git, GitHub, Bitbucket, Maven, Jenkins, CircleCI, TravisCI, Docker, Sonar, Kubernetes, Linux, Scripts, Vercel, Railway</p>
                        </article>
                        <article>
                            <h3>E<span className="smallCaps">lectronics</span></h3>
                            <p>C, C++, Python, Js, Assembly, VHDL, Verilog
                                <br />Electronic circuits, PCB design, Integrated circuits, Digital eletronics, IoT, LoraWAN, Wearables
                                <br />Arduino, ESPressif, RaspberryPi, Microchip, TTN, RTOS
                            </p>
                        </article>
                    </div>
                    <div className="section__foot"></div>
                </section>

                <section className="section section--primary section--tools">
                    <h2 className="section__title section__title--white">Tools</h2>
                    <div className="section__body">
                        <article>
                            <h3>Tech</h3>
                            <p>
                                <strong>
                                    Wordpress, Visual Studio, Postman, FileZilla, Gitbash, Atlas, Adobe Suite, Jest, Lens, GitLab, Terminal
                                    , paint.Net, Filmora, Jira, Teams, Slack, Excel, Putty, WinSCP
                                </strong>
                            </p>
                        </article>
                        <article>
                            <h3>Perks</h3>
                            <ul>
<li>Skilled and experienced in Agile Development process for diverse requirements</li>
<li>Worked on different operating systems, software platforms and application development</li>
<li>Ability to work confidently and passionately until the desired outcome is achieved</li>
<li>Strong analytical and problem solving skills</li>
<li>Excellent communication skills; both verbal and written</li>
<li>Willing to update my knowledge and learn new skills to perform efficiently</li>
                            </ul>
                            <img className='imgWorkflow' src={img_agile} alt="agile pic" />
                        </article>
                    </div>
                    <div className="section__foot"></div>
                </section>

                <section className="section section--orange section--timeline">
                    <h2 className="section__title">Experience</h2>
                    <div className="section__body">
                        <div className="timeline">

                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        2023 -xxxx
                                    </time>
                                    <span>Future employer</span>
                                </h3>
                                <span>Web developer</span>
                                <p>
                                    The future of development is paved with the tools we learn to use everyday.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        2021 -2023
                                    </time>
                                    <span>Critical Techworks</span>
                                </h3>
                                <span>Frontend developer</span>
                                <p>
                                As a Frontend Developer at Critical Techworks, a joint venture between BMW and Critical Software, I leveraged my skills in Angular.TS to create and manage cutting-edge applications in close collaboration with both customers and design teams. Working within Agile Scrum methodology, I collaborated with two teams to develop and test a range of applications using advanced technologies such as TestBed, Jest, and Spectator. Additionally, I gained some experience with backend development using Java, Springboot, and PostgreSQL, and leveraged Jenkins pipelines to ensure optimal quality control using Sonar. This experience allowed me to gain a deep understanding of the end-to-end software development process and how to deliver exceptional user experiences.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        2020 -2021
                                    </time>
                                    <span>Robert Mauser</span>
                                </h3>
                                <span>Specialized salesman</span>
                                <p>
                                As a specialized salesman with experience in an electronic components store, I have had the opportunity to work in both front-end customer service and back-end warehouse logistics roles. In my front-end customer service position, I developed strong communication and interpersonal skills, which enabled me to effectively engage with customers, provide support, and drive sales. In my back-end warehouse logistics role, I honed my organizational and problem-solving abilities, ensuring the timely and accurate delivery of products to customers. Overall, my experience in both roles has equipped me with a comprehensive understanding of the electronic components industry, as well as the skills needed to succeed in a dynamic and fast-paced environment.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        2020
                                    </time>
                                    <span>Critical Software</span>
                                </h3>
                                <span>Summer camp intern</span>
                                <p>
                                During the summer, I had the opportunity to participate in a highly sought-after internship program at Critical Software, where I gained valuable exposure to the professional IT environment. As an intern, I had the chance to work alongside experienced professionals, learn from their expertise, and contribute to real-world projects. This experience allowed me to gain a deeper understanding of software development processes, quality assurance, and project management. I also developed essential skills such as problem-solving, communication, and teamwork, which I believe will prove valuable in my future career. The Critical Software summer internship was an invaluable experience that has further motivated me to pursue a career in IT.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        20215 -2020
                                    </time>
                                    <span>Ergoface, <span style={{fontVariant: "all-small-caps"}}>mobiliário de escritório e projectos, lda</span></span>
                                </h3>
                                <span>Salesman and executive secretary</span>
                                <p>
                                At Ergoface, I held the roles of Salesman and Executive Secretary. This family-owned business specializes in office furniture retail, and I was responsible for various tasks including sales, product assembly, and office paperwork. During my tenure, I gained valuable experience in customer service, sales, and administrative duties. Working closely with clients, I helped them find the perfect furniture to meet their needs and provided assembly and installation services to ensure their satisfaction. As an Executive Secretary, I also assisted in managing the office's administrative tasks, such as paperwork and data entry. My time at Ergoface taught me the importance of attention to detail, excellent communication skills, and the ability to manage multiple tasks simultaneously.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        2020 -2021
                                    </time>
                                    <span>World Academy</span>
                                </h3>
                                <span>Frontend Web Development</span>
                                <p>
                                I completed a comprehensive Frontend Web Development program at World Academy, a leading professional school for multimedia, arts, and digital events. Throughout this program, I gained extensive knowledge in web structure and communication, with a focus on the latest developments in HTML5, CSS3, ES6, and the React framework, as well as React Native. The program also included a module on Wordpress and UxUi, which covered core values such as SEO, prototypes, and interface design, using tools such as Figma and Adobe XD. Overall, this program equipped me with the skills and knowledge necessary to develop high-quality, responsive websites and user interfaces that meet the latest industry standards.
                                </p>
                            </article>
                            <article className="timeline__item">
                                <h3>
                                    <time className="date-range">
                                        20215 -2020
                                    </time>
                                    <span>IST
                                        <span className='smallCaps'>
                                            , Instituto Superior Técnico 
                                        </span>
                                    </span>
                                </h3>
                                <span>Computer and electronics Master</span>
                                <p>
                                IST is a leading engineering college in Europe and the world, renowned for its excellence in education and research. As a student in the Computer and Electronics Master's Program, I gained a deep understanding of physics, mathematics, programming, algorithms, and other key topics. Beyond the knowledge gained in the classroom, I formed long-lasting friendships and developed an object-oriented mindset that has served me well in my professional pursuits.
<br />
While I have temporarily frozen my enrollment in the program, I am eagerly looking forward to resuming my studies in 2023. My time at IST has played a significant role in shaping my academic and professional trajectory.
                                </p>
                            </article>

                            <svg className="timeline__icon timeline__icon--top">
                                <use xlinkHref="#timeline-top" />
                            </svg>
                            <svg className="timeline__icon timeline__icon--bottom">
                                <use xlinkHref="#timeline-bottom" />
                            </svg>
                        </div>
                    </div>
                    <div className="section__foot"></div>
                </section>

                <section className="section section--secondary section--profile">
                    <h2 className="section__title section__title--primary">Profile</h2>
                    <div className="section__body grid grid--50">
                        <article>
                            <h3>Maker</h3>
                            <p>
                            I create innovative designs with a can-do attitude, using the latest tools and technologies to turn sketches into high-quality prototypes. Whether working alone or with a team, I am dedicated to delivering exceptional results.
                            </p>
                        </article>
                        <article>
                            <h3>Team</h3>
                            <p>
                            A team is more than individuals working together - it's a collective effort where "I" becomes "We," striving towards a common goal. I value the purpose and commitment of team work, from school and hackathons to professional projects. I bring my best to contribute and help the team go further.
                            </p>
                        </article>
                        <article>
                            <h3>Comm</h3>
                            <p>
                                True magic happens when shared ideas are listened and understood.
                                <br /><i>A different language is a different vision of life.</i>
                                <br /><strong>I know:</strong> 
                                <br />Português, English, Español
                                <br /><strong>I'm learning:</strong>
                                <br />Deutsh
                            </p>
                        </article>
                        <article>
                            <h3>Hobbies</h3>
                            <p>
                            In my spare time, I enjoy playing guitar, walking my dogs in nature, building digital and artistic projects, and disconnecting from technology by heading deep into the wilderness. I'm also passionate about photography and cinematography, capturing unique moments and perspectives to see the world in different ways.
                            </p>
                        </article>
                    </div>
                </section>
            </div>
            <div className="page" data-page="portfolio" ref={portfolioRef}>

                <section className="section section--secondary section--summary">
                    <div className="section__body">
                        <article>
                            <h3>WebApps</h3>
                            <p>Built over the browser to display and gather information from the user and to the user. I'm keen on using React with Next.js on the frontend and Node with Express in backend with PostgreSQL or Mongo database. 
                            </p>
                        </article>
                        <article>
                            <h3>Electronics</h3>
                            <p>Built with transistors, components and ATmel, ESPressif uControllers with Arduino framework.
                            <br />I'm interested in deploying a Azure RTOS ThreadX IoT and LoRaWAN program, using WebApps as bridge to digital and physical world.
                             </p>
                        </article>
                    </div>
                    <div className="section__foot"></div>
                </section>

                <section className="section section--orange section--portfolio-1">
                    <h2 className="section__title section__title--primary">Web Apps</h2>
                    <div className="section__body">
                        <a href="https://ergoface-lda.com/app" target="_blank">
                            <figure className='ergofaceLogo'>
                                <img src={ergofaceLogo} alt="Ergoface Logo" />
                            </figure>
                        </a>
                        <a href="https://jcolaco.dev/o-churrasco/home/" target="_blank">
                            <figure className='ergofaceLogo'>
                                <img src={churrascoLogo} alt="Churrasco Logo" />
                            </figure>
                        </a>
                        <figure className='underConstruction'>
                            <img src={underConstruction} alt="under Construction" />
                        </figure>
                    </div>
                </section>

                <section className="section section--primary section--portfolio-2">
                    <h2 className="section__title">Electronics</h2>
                    <div className="section__body" style={{paddingBottom: "1.6rem"}}>
                        <figure className='underConstruction'>
                            <img src={underConstruction} alt="under Cunstruction" />
                        </figure>
                    </div>
                </section>
            </div>
            <div className="page" data-page="projects" ref={projectsRef}>
                <section className="section section--secondary section--summary">
                    <div className="section__body">
                        <article>
                            <h3>Web App Developer</h3>
                            <p></p>
                        </article>
                    </div>
                    <div className="section__foot"></div>
                </section>
                <section className="section section--orange section--projects-1">
                    <h2 className="section__title section__title--primary">What drives me</h2>
                    <div className="section__body">
                        <p>
                            I want to build meaningful fullstack apps in a continuous integration and deployment pipeline, that create impact. <br />
                            Using the power of the internet to develop creative solutions integrated with ioT devices.
                        </p>
                    </div>
                </section>
                <section className="section section--primary section--projects-2">
                    <h2 className="section__title section__title--secondary">Hackathons</h2>
                    <div className="section__body">
                        <p><em>“Hackathons are where your crazy idea becomes reality” – Alex Kern</em></p>
                        <ul>
                            <li><a href="taikai.network/en/cityhack/challenges/city-hack-2021/projects/ckp1yk9ak13436020rugdbzyuzmt/idea" target="_blank" rel="noreferrer">
                            Future City2022- Compass Rose</a>
                                <br />
                                Network of digital compass roses connected via TTN LoRaWAN to show users the best path from A to B considering weather forecasts and live information. It's powered by Arduino, sensors and actuators, RaspberryPi and LoRa communications.
                                <span className="pill">10 finalists</span>
                            </li>
                            <li><a href="taikai.network/en/cityhack/challenges/city-hack-2021/projects/ckp1yk9ak13436020rugdbzyuzmt/idea" target="_blank" rel="noreferrer">
                            CityHack2021- Smart Environment</a>
                            <br />
                                Network of sensors and actuators via LoRa, BT and WiFi to manage environmental data and act accordingly. It's powered by Arduino with dedicated server and database.
                                <span className="pill">3rd place</span>
                            </li>
                            <li><a href="taikai.network/en/cityhack/challenges/city-hack-2020/projects/ckak3a179fj4808646dysqmc9/idea" target="_blank" rel="noreferrer">
                                CityHack2020- eMotion Flow</a>
                                <br />
                                Network of ioT RADAR/InfraRed electronic control system that counts the occupancy rate of a public place, providing real-time data and protecting Public Health.
                                <span className="pill">2nd place</span>
                            </li>
                            <li><a href="taikai.network/en/cityhack/challenges/city-hack-2021/projects/ckp1yk9ak13436020rugdbzyuzmt/idea" target="_blank" rel="noreferrer">
                            Tech4Covid19- Q-Card</a>
                            <br />
                                A card that is a ticket for a line queue in any commercial space(RFID, NFC or QRcode) People register their turn by swiping these or Online, than receive an SMS with their number and updates on the Q!
                                <span className="pill">2nd place</span>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="section section--secondary section--projects-3">
                    <h2 className="section__title section__title--white">Bookmarks</h2>
                    <div className="section__body">
                        <p>When working as a web developer there are certain tasks that we tend to do daily. Some become tedious and repetitive, so <em>Boost your productivity with a curated list of web tools!</em></p>
                        <ul>
                            <li>
                                <a href=" https://caniuse.com" target="_blank" rel="noreferrer">
                                    Can I use
                                </a> Understand the support of the native browser features we rely on
                            </li>
                            <li>
                                <a href="https://smalldev.tools/" target="_blank" rel="noreferrer">
                                    Small Dev Tools
                                </a> Swiss knife for web utilities
                            </li>
                            <li>
                                <a href="https://vscode.dev" target="_blank" rel="noreferrer">
                                    Vscode Dev
                                </a> VSCode running on Web
                            </li>
                            <li>
                                <a href="https://www.typescriptlang.org/" target="_blank" rel="noreferrer">
                                    TypeScript Playground
                                </a> Simple and lightweight TypeScript playground
                            </li>
                            <li>
                                <a href="" target="_blank" rel="noreferrer">
                                    GraphQL Playground
                                </a> Visually explore Apollo Server and GraphQL best practices
                            </li>
                            <li>
                                <a href="https://regex101.com/" target="_blank" rel="noreferrer">
                                    Regex 101
                                </a> For testing regular expressions and optimize it
                            </li>
                            <li>
                                <a href="https://metatags.io " target="_blank" rel="noreferrer">
                                    Meta Tags
                                </a> The Meta Tags has a clean UI and support a bunch of providers
                            </li>
                            <li>
                                <a href="https://www.opengraph.xyz/" target="_blank" rel="noreferrer">
                                    The Open Graph
                                </a> OpenGraph is the easiest way to preview and generate open graph meta tags for any website
                            </li>
                            <li>
                                <a href="https://www.w3schools.com/cssref/css_selectors.asp" target="_blank" rel="noreferrer">
                                    CSS Tools
                                </a> CSS Selector Reference
                            </li>
                            <li>
                                <a href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/" target="_blank" rel="noreferrer">
                                    Flexbox layout
                                </a> A Complete Guide to Flexbox
                            </li>
                            <li>
                                <a href="https://lesstester.com/" target="_blank" rel="noreferrer">
                                LESS Tester
                                </a> See if the rendered CSS is what you expect it to be
                            </li>
                            <li>
                                <a href="https://www.sassmeister.com/" target="_blank" rel="noreferrer">
                                SASS Meister
                                </a> For those of us who use SASS
                            </li>
                            <li>
                                <a href="https://bennettfeely.com/clippy/" target="_blank" rel="noreferrer">
                                    Clippy
                                </a> Create complex shapes
                            </li>
                            <li>
                                <a href="https://www.lipsum.com/" target="_blank" rel="noreferrer">
                                    Lipsum
                                </a> Dummy Text Generator
                            </li>
                            <li>
                                <a href="http://www.cupcakeipsum.com" target="_blank" rel="noreferrer">
                                    Cupcake Ipsum
                                </a> Generates random dummy text related to sugary sweets
                            </li>
                            <li>
                                <a href="https://www.mobilefish.com/services/base64/base64.php" target="_blank" rel="noreferrer">
                                    Base64 Encoder and Decoder
                                </a> Use this for icons and then use the base64 data in CSS to render it
                            </li>
                            <li>
                                <a href="https://www.diffchecker.com/" target="_blank" rel="noreferrer">
                                Diffchecker
                                </a> Quickly compare differences between text files
                            </li>
                            <li>
                                <a href="https://realfavicongenerator.net/" target="_blank" rel="noreferrer">
                                  Favicon Generator
                                </a>
                            </li>
                            <li>
                                <a href="https://www.paypalobjects.com/en_AU/vhelp/paypalmanager_help/credit_card_numbers.htm" target="_blank" rel="noreferrer">
                                    Test Credit Card Account Numbers
                                </a> Deal with payment systems, use test credit card numbers when testing the functionality
                            </li>
                            <li>
                                <a href="https://www.webpagetest.org" target="_blank" rel="noreferrer">
                                    Web Page Test
                                </a> This tool helps understand who the web application is being shipped to the User’s browser and site performance SEO
                            </li>
                            <li>
                                <a href="https://www.schemecolor.com" target="_blank" rel="noreferrer">
                                    SchemeColor
                                </a> Create a consistent scheme color palette
                            </li>
                            <li>
                                <a href="animista.net" target="_blank" rel="noreferrer">
                                    Animista
                                </a> Animista creates CSS animations interactively
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        </main>
        
        <footer className="cv__foot">
            <article className="copyright">
                web Version <em>Curriculum Vitae</em> <strong>©&nbsp;jColaco.dev&nbsp; 
                   <br /> </strong>by <em>João Colaço</em>
                <script>
                    document.write(new Date().getFullYear());
                </script>
            </article>
        </footer>
    </div>
    </div>
    </div>
);
}