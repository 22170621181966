import React from 'react'
import { Link } from 'react-router-dom';
import "./StickyNav.scss"
import "../../../Animations/underlineText.scss";

export default function StickyNav({navLinks}) {
    const [displayNav, setDisplayNav] = React.useState(true);
    const [scrollDir, setScrollDir] = React.useState("scrolling down");
    
    React.useLayoutEffect(() => {
    }, [navLinks]);

    React.useEffect(() => {
      const threshold = 100;
      let lastScrollY = window.pageYOffset;
      let ticking = false;
    
      const updateScrollDir = () => {
        const scrollY = window.pageYOffset;
    
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };
    
      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };
    
      window.addEventListener("scroll", onScroll);
    //   console.log(scrollDir); 
    setDisplayNav(scrollDir === "scrolling up" ? true : false);
      
      return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);
    
    React.useLayoutEffect(() => {
        const themeToggleSelector = document.querySelector(".ThemeToggle");
        displayNav ? 
            themeToggleSelector?.classList.remove("hideNav") :
            themeToggleSelector?.classList.add("hideNav");
    }, [displayNav]);

    return (
        <nav className= {`stickyNav hover-underline07 ${displayNav ? "" : "hideNav"}`}>
            {navLinks && navLinks?.map((navLink, i)=>
                <Link key={i} to={{hash:navLink.ref}}>{navLink.label}</Link>    
            )}
        </nav>
  )
}
