import React, { Component } from "react";
import "./TextEffect1.scss";

export default class TextEffect1 extends Component {
    render() {
        return (
            <div className="TextEffect1">
                <div className="container">
                    <p>Hi, my name is...</p>
                    <h1>João Colaço</h1>
                </div>
            </div>
        );
    }
}
