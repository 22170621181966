import React, {useContext, useState, useEffect} from 'react'
import { Link, Outlet } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import LoadingToRedirect from './LoadingToRedirect';
import { AccountAppData } from '../Apps/AccountApp/AccountApp';

export default function PrivateRoute({urlPath}) {
    const {state} = useContext(AuthContext);
    const [user, setUser] = useState(false);
  
    useEffect(() => {
        if(state.user){
            setUser(true);
        }
    }, [state.user])

    const renderContent = 
        <div className='container-fluid pt-5'>
                <div className="col-md-8">
                  <Outlet/>
                </div>
        </div>
        
    
    return user ? renderContent : <LoadingToRedirect timer={5} path={`${AccountAppData.path}/login`}/>
}
