import "./Info.scss"

const Info = () => {
	return (
		<div className="info">
			<div className="player">Player 1: X</div>
			<div className="player">Player 2: O</div>
		</div>
	)
}

export default Info;
