import '../Apps.scss'
import './PostsApp.scss'
import React from 'react'

import Wall from './routes/Wall/Wall';
import SingleUser from './routes/SingleUser';
import SinglePost from './routes/SinglePost';
import PostUpdate from './routes/PostUpdate';
import Post from './routes/Post/Post';
import LoadingToRedirect from '../../components/LoadingToRedirect';

export const PostsAppData = {
    id: "PostsApp",
    path: "/postsApp",
    label: "Posts",

    routesData: [
        {
            path: "/wall",
            type: "",
            element: <Wall />,
            title: "Wall",
            navLinks: []
        },
        {
            path: "/singleuser",
            type: "private",
            element: <SingleUser />,
            title: "Single user",
            navLinks: []
        },
        {
            path: "/singlepost",
            type: "",
            element: <SinglePost />,
            title: "Single post",
            navLinks: []
        },
        {
            path: "/update/:postid",
            type: "private",
            element: <PostUpdate />,
            title: "Post update",
            navLinks: []
        },
        {
            path: "/post",
            type: "private",
            element: <Post />,
            title: "Post",
            navLinks: []
        },
    ]
}