import './Wall.scss'
import React, {useContext, useState} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../../../context/authContext';
import { useNavigate } from "react-router-dom";
import { GET_ALL_POSTS, TOTAL_POSTS } from '../../../../graphql/queries';
import PostCard from '../../../../components/PostCard/PostCard';
import Pagination1 from '../../../../components/pagination/Pagination1/Pagination1';

const Wall = () => {
  let navigateTo = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const {state, dispatch} = useContext(AuthContext);
  const { data, loading, error } = useQuery(GET_ALL_POSTS, {
    variables: {
      perPage,
      page
    },
  });
  const {data: postCount} = useQuery(TOTAL_POSTS)
  const [fetchPosts, {data: postsData}] = useLazyQuery(GET_ALL_POSTS);
  let totalPages = Math.ceil(postCount && postCount.totalPosts / perPage);
  
  return (
    <div className="Wall">
      <div>
        <h1>Posts Wall</h1>
        <p>
          These posts are held in a mongoDB cloud database. <br />
          Each post belongs to a user registered in the database. <br /> 
          Register an account or just login with Google to be able to post what's on your mind!
        </p>
      </div>
      {loading ? <p>Loading...</p> :
        <section>
          {data &&
            data.allPosts.map((post) => (
              <article key={post._id}>
                <PostCard post={post} />
              </article>
            ))}
        </section>
      }
      <Pagination1 
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default Wall;
