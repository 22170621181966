import React from "react";
import "./Cubes.scss";
import NavigationDotted from "../../navigation/NavigationDotted/NavigationDotted";

import img_siddartha from "./imgs/siddartha.jpg"
import img_milan from "./imgs/milan.jpg"
import img_dorian from "./imgs/dorian.jpg"
import img_eSunshine from "./imgs/eternalSunshine.jpg"
import img_vitaBella from "./imgs/vitaBella.jpg"
import img_benHur from "./imgs/benHur.jpg"
import img_gorillaz from "./imgs/gorillaz.jfif"
import img_queen from "./imgs/queen.jpg"
import img_manu from "./imgs/manu.jpg"
import img_natural from "./imgs/natural.jpg"
import img_festival from "./imgs/festival.jpg"
import img_workshop from "./imgs/workshop.jpg"

export default function Cubes() {
    const [navSel, setNavSel] = React.useState(0);

    const cssSel = ["", "first", "second", "third"];
    const booksData = [
        {
            imgSrc: img_siddartha,
            title: "Siddhartha",
            creator: "Hermann Hesse",
            date: 1922
        },
        {
            imgSrc: img_milan,
            title: "Unbearable Lightness of Being",
            creator: "Milan Kundera",
            date: 1984
        },
        {
            imgSrc: img_dorian,
            title: "Picture of Dorian Gray",
            creator: "Oscar Wilde",
            date: 1890
        }
    ];
    const moviesData = [
        {
            imgSrc: img_eSunshine,
            title: "Eternal Sunshine of the Spotless Mind",
            creator: "Jim Carrey",
            date: 2004
        },
        {
            imgSrc: img_vitaBella,
            title: "La Vita è Bella",
            creator: "Roberto Benigni",
            date: 1999
        },
        {
            imgSrc: img_benHur,
            title: "Ben-Hur",
            creator: "Lew Wallace",
            date: 1959
        }
    ];
    const musicData = [
        {
            imgSrc: img_gorillaz,
            title: "Gorillaz",
            creator: "electronic",
            date: 1998
        },
        {
            imgSrc: img_queen,
            title: "Queen",
            creator: "Rock n'Roll",
            date: 1970
        },
        {
            imgSrc: img_manu,
            title: "Manu Chao",
            creator: "Alternative SKA",
            date: 1987
        }
    ];
    const placesData = [
        {
            imgSrc: img_natural,
            title: "Nature",
            creator: "everywhere",
            date: "nowhere"
        },
        {
            imgSrc: img_festival,
            title: "Festival",
            creator: "music",
            date: "camping"
        },
        {
            imgSrc: img_workshop,
            title: "Workshop",
            creator: "developing",
            date: "creating"
        }
    ];

    const data = [
        booksData,
        moviesData,
        musicData,
        placesData
    ]
    const handleClick = num => {
        setNavSel(num);
      };

    return (
        <div className="Cubes">
            <NavigationDotted handleClick={handleClick}></NavigationDotted>
            <section className="cubes-container">

                {booksData.map((e,i)=>
                <article key={i} className="cubeData">
                    <div className="cube-container">
                        <div className={`photo-cube ${cssSel[navSel]}`}>
                            <img className="front" src={data[0][i].imgSrc} alt={data[0][i].title} />
                            <img className="left"  src={data[1][i].imgSrc} alt={data[1][i].title} />
                            <img className="right" src={data[2][i].imgSrc} alt={data[2][i].title} />
                            <img className="back"  src={data[3][i].imgSrc} alt={data[3][i].title} />
                        </div>
                    </div>

                    <section>
                        <h1>{data[navSel][i].title}</h1>
                        <p>by {data[navSel][i].creator}</p>
                        <span>{data[navSel][i].date}</span>
                    </section>
                </article>
                )}
            </section>
        </div>
    );
}
