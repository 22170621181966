import './UserCard.scss'
import React from 'react'
import Image from '../Image'
import { Link } from 'react-router-dom';

export default function UserCard({user}) {

    const {username, images, about} = user;

  return (
        <div className="UserCard">
            <main>
                <Image image={images[0]} />
                <Link to={`/user/${username}`}>
                    <h4>@{user.username}</h4>
                </Link>
                <hr />
                <small>{about}</small>
            </main>
        </div>
    )
}
