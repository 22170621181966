import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Apollo & gql
import { 
  ApolloClient, 
  InMemoryCache, 
  ApolloProvider,
  split,
  HttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import Theme from './Theme';
import { AuthContext } from './context/authContext'
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './Hooks/ScrollToTop';
import { routerSetup } from './utils/router';

import { AccountAppData } from './Apps/AccountApp/AccountApp';
import { WebPortfolioAppData } from './Apps/WebPortfolioApp/WebPortfolioApp';
import { PostsAppData } from './Apps/PostsApp/PostsApp';

import StickyNav from './components/navigation/StickyNav/StickyNav';
import Footer from './components/navigation/Footer/Footer';
import ThemeToggle from './components/buttons/ThemeToggle/ThemeToggle';
import MenuButton from './components/burgerMenu/MenuButton/MenuButton';
import DayNight1 from './components/animations/DayNight1/DayNight1';
import { ParallaxBanner1 } from './components/parallaxBanner/ParallaxBanner1/ParallaxBanner1/ParallaxBanner1';

import { setAppPath } from './Apps/appSlice';
import LoadingToRedirect from './components/LoadingToRedirect';

export const AppsData = [
  AccountAppData,
  WebPortfolioAppData,
  PostsAppData
]

const App = () => {
  const dispatch = useDispatch();
  const [currentAppBasePath, setCurrentAppBasePath] = useState(`/${location.pathname.split('/')[1] || ""}`);
  const [currentAppPath, setCurrentAppPath] = useState(`/${location.pathname.split('/')[2] || ""}`);
  const [appData, setAppData] = useState();
  const [currentPageLinks, setCurrentPageLinks] = useState([]);
  
  const {state} = useContext(AuthContext);
  const {user} = state;
  const stateTheme = useSelector((state) => state.theme);

  const topRef = useRef();
  const topAppRef = useRef();
  const navigate = useNavigate()

  useEffect(()=>{
    AppsData.map((_appData)=>{
      if(_appData.path.toLowerCase() === currentAppBasePath.toLowerCase()){
        setAppData(_appData);
      }
    });
  },[])

  useEffect(()=>{
    const _currentAppBasePath = `/${location.pathname.split('/')[1] || ""}`
    const _currentAppPath = `/${location.pathname.split('/')[2] || ""}`
    setCurrentAppBasePath(_currentAppBasePath)
    setCurrentAppPath(_currentAppPath)
    setCurrentPageLinks(pageLinksFromAppData(appData, _currentAppPath));
      
    AppsData.map((_appData)=>{
      if(_appData.path.toLowerCase() === _currentAppBasePath.toLowerCase()){
        setAppData(_appData);
        setCurrentPageLinks(pageLinksFromAppData(_appData, _currentAppPath));
        return;
      }
    });
  },[navigate])
  
  useEffect(()=>{
    setCurrentPageLinks(pageLinksFromAppData(appData, currentAppPath));
    dispatch(setAppPath(appData?.path || '/'));
  },[appData])


  function pageLinksFromAppData(appData, appPath) {
    const pageLinks = appData?.routesData?.filter((routeData)=>routeData.path === appPath)[0]?.navLinks || [];

    return pageLinks;
  }


  // Apollo related
  
  const wsLink = new GraphQLWsLink(createClient({
    url: import.meta.env.VITE_GRAPHQL_WS_ENDPOINT,
    options: {
      shouldRetry: true
    }
  }));
  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_GRAPHQL_HTTP_ENDPOINT,
  });
  
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authtoken: user ? user.token : ''
      }
    }
  });
  const httpAuthLink = authLink.concat(httpLink);
  
  const splitLink = split(({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  }, wsLink, httpAuthLink)   

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false
    })
  });

  return (
    <ApolloProvider client={apolloClient}>
      <Theme />
      <ToastContainer className="marginMenu"/>
      <ScrollToTop />
      <span ref={topRef} id="top" />

      <ThemeToggle themeToggle={stateTheme} />
      <MenuButton appData={appData}/>

      <div id={"appContainer"}>
        <div className="marginMenu">
            <div id="landingParallax">
                <DayNight1 theme={stateTheme}></DayNight1>
                <ParallaxBanner1 />
            </div>
              <StickyNav navLinks={currentPageLinks} />
              <span ref={topAppRef} id="topApp" />
              <main className='appRoutes'>
                <Routes>
                  {AppsData.map((appData)=>routerSetup(appData))}
                  <Route path='*' 
                    element={<LoadingToRedirect 
                      path={`${WebPortfolioAppData.path}`} />} 
                      />
                </Routes>
              </main>
            <Footer />
          </div>
      </div>
    </ApolloProvider>
  );
};

export default App;
