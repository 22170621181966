import "./CurriculumV.scss";
import { Animated } from 'react-animated-css'
import CV from "../../../../components/lifeResumes/CV/CV";

const CurriculumV = () => {
    return (
        <Animated 
                animationIn="fadeIn" 
                animationOut="fadeOut"
            >
            <div className="CurriculumV">
                <CV/>
            </div>
        </Animated>
    );
};

export default CurriculumV;
