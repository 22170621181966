import React from 'react'
import "./Studio.scss"
import TheBuilding from '../../../../components/rooms3d/TheBuilding/TheBuilding';


const Studio = () => {

    return (
        <div className="Studio">
            <TheBuilding></TheBuilding>
        </div>
    );
};

export default Studio;
