import './Sidebar.scss'
import React, { useRef } from 'react'
import {svg_icons} from '../icons';
import { useEffect } from 'react';

export default function Sidebar({
    setPageIndex = (f)=>f, 
    pageIndex=0,
    accountPages = []
  }) {

    const ulRef = useRef();

    useEffect(()=>{
      setPageIndex(0)
    }, []);

    useEffect(()=>{
      const itemList = ulRef.current.querySelectorAll(".sidebar-list-item");
      for(let item of itemList){
        if(item.dataset.index == pageIndex)
          item.classList.add("active")
        else
          item.classList.remove("active")
      }
    }, [pageIndex])
    
    function handleListItemClick(e){
        e.preventDefault();
        setPageIndex(e.currentTarget.dataset.index)
    }

    return(
    <div className="sidebar">
    <div className="sidebar-header">
      <div className="app-icon">{svg_icons.logo}</div>
      my Account
    </div>
    <ul ref={ulRef} className="sidebar-list">
      {accountPages.map((page, i)=>
        <li key={i} data-index={i} onClick={handleListItemClick} className="sidebar-list-item">
            <span>
                {page.icon}
                <span>{page.label}</span>
            </span>
        </li>
      )}
    </ul>

    {/* <div className="account-info">
      <div className="account-info-picture">
        <img src="https://images.unsplash.com/photo-1527736947477-2790e28f3443?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTE2fHx3b21hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60" alt="Account" />
      </div>
      <div className="account-info-name">Monica G.</div>
      <button className="account-info-more">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/></svg>
      </button>
    </div> */}
  </div>
)}