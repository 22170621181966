import "./LandingPage.scss";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Animated } from 'react-animated-css'

import CanvasPortrait from "../../../../components/pictureFrames/CanvasPortrait/CanvasPortrait";
import TextEffect1 from "../../../../components/textStyle/TextEffect1/TextEffect1";
import ContactLetter from "../../../../components/objectsUI/ContactLetter/ContactLetter";
import TextQuote from "../../../../components/textStyle/TextQuote/TextQuote";
import GalleryPollaroid from "../../../../components/pictureFrames/GalleryPollaroid/GalleryPollaroid";
import Cubes from "../../../../components/sliders/Cubes/Cubes";

import img_favThingsWhite from "./favThingsWhite.png"
import img_favThings from "./favThings.png"
import { useLocation } from "react-router-dom";

const LandingPage = () => {
    const location = useLocation();
    const theme = useSelector((state) => state.theme);
    const [imgSrc, setImgSrc] = React.useState("");

    const aboutMeRef = useRef();
    const interestsRef = useRef();
    const contactMeRef = useRef();
    const pageRefs = [aboutMeRef, interestsRef, contactMeRef]

    const myIntroTxt = <p className="font_monospace">
        this.webApp.uses({'{'} <br />
        &emsp;&emsp;frontend: (Vite + React.js), <br />
        &emsp;&emsp;backend: (Express + Node.js), <br />
        &emsp;&emsp;database: (GraphQL + mongoDB), <br /> 
        &emsp;&emsp;providers: {'['} Firebase, Apollo, Redux, reCAPTCHA, Parallax, Cloudinary, ChatGPT <br />
        &emsp;&emsp;{']'}<br />
        {'});'}<br />
        <br />
        I'm working on automating the deployment process for this project to make it faster and more efficient. We'll be using a TravisCI pipeline triggered by a master Github push, along with Docker containers and Kubernetes. This will allow us to push updates seamlessly to the server, reducing downtime and improving the user experience.

        <br /><br /> 
        Create(account_and_login)! It will give you access to the private routes and {`<User>`} centered apps!
        <br /><br /> 
        Browse between pages.toFind( more_about_me) and please report back any bug you catch! 
        <br /><br />
        Feel free to contact me about anything using the eLetter below! =)

    </p>;

    React.useEffect(() => {
        setImgSrc(theme === "darkTheme" ? img_favThingsWhite : img_favThings);
    }, [theme]);

    React.useEffect(() => {
        pageRefs.map(({current}) => {
            if(location.hash === `#${current.dataset.page}`)
                current.scrollIntoView({behavior: 'smooth'})
        });

    }, [location.hash]);


    return (
        <Animated 
            animationIn="fadeIn" 
            animationOut="fadeOut"
        >
        <main className="LandingPage">
            <section data-page="aboutMe" ref={aboutMeRef} id="aboutMe">
                <TextEffect1 />
                <p>Welcome to my personal domain & <span className="font_starwars">may the force be with you.</span></p>
                <p>
                Check my new Portfolio at: <a href="https://jcolaco.dev/portfolio/" target="_blank"><u><em>https://jcolaco.dev/portfolio</em></u></a>
                </p>

                <div className="flexGrid">
                    <CanvasPortrait />
                    <div className="myIntro">
                        {myIntroTxt}
                    </div>
                </div>
            </section>
            <section data-page="interests" ref={interestsRef} id="interests">
                <figure><img src={imgSrc} alt="" /></figure>
                <GalleryPollaroid />
                <Cubes />
            </section>
            <section data-page="contactMe" ref={contactMeRef} id="contactMe">
                <TextQuote />
                <ContactLetter />
            </section>
        </main>
    </Animated>
    );
};

export default LandingPage;
