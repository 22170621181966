import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "../Theme/themeSlice";
// import sunsetReducer from "../components/animations/Sunset/SunsetSlice";
import appPathReducer from "../Apps/appSlice";

const store = configureStore({
    reducer: {
        theme: themeReducer,
        appPath: appPathReducer,
        // sunset: sunsetReducer,
    },
});

export default store;
