import './Post.scss'
import React, { useState } from 'react'
import {toast} from 'react-toastify'
import { useQuery, useMutation } from '@apollo/client'
import FileUpload from '../../../../components/FileUpload'
import { POST_CREATE, POST_DELETE } from '../../../../graphql/mutations'
import { POSTS_BY_USER } from '../../../../graphql/queries'
import PostCard from '../../../../components/PostCard/PostCard'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const initialState = {
    content: '',
    images: []
}

export default function Post() {
      const stateAppPath = useSelector((state) => state.appPath);

    const [values, setValues] = useState(initialState)
    const [loading, setLoading] = useState(false)
    
    const navigate = useNavigate();
    
    // query
    const {data: postsData} = useQuery(POSTS_BY_USER);

    // mutation
    const [postDelete] = useMutation(POST_DELETE, {
        update: ({data})=> {
            console.log("POST DELETE MUTATION", data);
            toast.error('Post deleted');
        },
        onError: (err) => {
            console.log(err);
            toast.error("Post delete failed");
        }
    });
    const [postCreate] = useMutation(POST_CREATE, {
        // update cache
        update: (cache, {data: {postCreate}}) => {
            // readQuery from cache
            const {postsByUser} = cache.readQuery({
                query: POSTS_BY_USER
            });
            // writeQuery to cache
            cache.writeQuery({
                query: POSTS_BY_USER,
                data: {
                    postsByUser: [postCreate, ...postsByUser]
                }
            })
        },
        onError: (err) => console.log(err.graphqQLError[0].message)
    });

    // destructure
    const {content, images} = values;

    const handleUpdate = async postId => {
        navigate(`${stateAppPath}/update/${postId}`)
    }
    const handleDelete = async (postId) => {
        let answer = window.confirm('Delete?');
        if (answer) {
            setLoading(true);
            postDelete({
                variables: { postId },
                refetchQueries: [{ query: POSTS_BY_USER }]
            });
            setLoading(false);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        postCreate({variables: {input: values}});
        setValues(initialState);
        setLoading(false);
        toast.success('Post created');
    }
    
    function handleChange(e){
        e.preventDefault();
        setValues({...values, [e.target.name]: e.target.value})
    }
    
    const createForm= <form onSubmit={handleSubmit}>
        <div>
            <textarea
                value={content}
                onChange={handleChange}
                name="content"
                rows="10"
                placeholder="Write Something"
                maxLength="150"
                disabled={loading}
            ></textarea>
        </div>
        <button type="submit" disabled={loading || !content}>Post</button>
    </form>

    return <div className='Post'>
        {loading ? 
            <h4>Loading...</h4> : 
            <h2>Create a Post</h2>
        }
        <section className="uploadSection">
            <FileUpload 
                values={values}
                loading={loading}
                setValues={setValues}
                setLoading={setLoading}
                />
            {createForm}
        </section>
        <section className='postCardsSection'>
        {postsData && postsData.postsByUser.map((post) => (
            <article key={post._id}>
                <PostCard post={post} 
                    showUpdateButton={true} 
                    showDeleteButton={true}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                />
            </article>
        ))}
        </section>
    </div>
}
