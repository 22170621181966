import '../Apps.scss'
import './AccountApp.scss'
import React from 'react'

import CompleteRegistration from './routes/CompleteRegistration';
import Login from './routes/Login';
import Dashboard from './routes/Dashboard/Dashboard';
import LoadingToRedirect from '../../components/LoadingToRedirect';

export const AccountAppData = {
    id: "AccountApp",
    path: "/account",
    label: "Account",

    routesData: [
        {
            path: "/dashboard",
            type: "private",
            element: <Dashboard />,
            title: "Dashboard",
            navLinks: []
        },
        {
            path: "/complete-registration",
            type: "public",
            element: <CompleteRegistration />,
            title: "Complete Registration",
            navLinks: []
        },
        {
            path: "/login",
            type: "public",
            element: <Login />,
            title: "Login",
            navLinks: []
        }
    ]
}