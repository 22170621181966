import React, { useEffect, useState } from 'react'
import './PictureFrame.scss'

export default function PictureFrame({pictures=[], autoPlay = -1}) {

    const [picture, setPicture] = useState({
        src: "https://via.placeholder.com/200x200.png?text=Picture",
        label: "Picture"
    })
    const [index, setIndex] = useState(0);

    useEffect(() => {
        
        if(autoPlay !== -1){
            const timer = setTimeout(() => {
                if(index >= pictures.length - 1) setIndex(0);
                else setIndex(index + 1);
                
            }, autoPlay);
            return () => clearTimeout(timer);
        }
    }, [autoPlay, index, pictures]);

    return <div className="PictureFrame">
    <div className="border">
      <div className="image">
        <img src={pictures[index].src} alt={pictures[index].label} />
      </div>
      <div className="text">{pictures[index].label}</div>
    </div>
  </div>
}
