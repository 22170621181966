import "./NeonScroll.scss"
import React from 'react'
import PDFmaker from "../../PDFmaker/PDFmaker"

export default function NeonScroll() {
return (
<div id="NeonScroll" className="NeonScroll">
	<div className="main">
		<div className="head">
			<h1 className="headline">News and Future</h1>
			<h2 className="subhead">
				This page is dedicated to ideas for current and future development!
				<br/> If you are interested, contact me to make it our project! 
			</h2>
			<div className="article-meta">
				<div className="author">
					<div className="author__info">
						<p className="byline">By: João Colaço</p>
						<p className="dateline">November, 2022</p>
					</div>
				</div>
				<p className="article-tags">
					<span className="tag">web Development</span> <span className="tag">Electronics</span>
					<span className="tag featured">connected</span>
				</p>
			</div>
		</div>
		<div className="content">
			<h2>CondoConnect- Condominium Management App</h2>
			<p>
			CondoConnect is a comprehensive condominium management app that provides an all-in-one solution for managing your building. Our app allows tenants to easily share alerts and oversee all matters related to their building, ensuring transparency and promoting community involvement.
<br />
CondoConnect also integrates the smart building concept, using a system of sensors and actuators connected to the internet of things (IoT) to enhance security and efficiency. This innovative feature allows for real-time monitoring of various building systems such as security, lighting, and energy consumption, allowing for quick and efficient maintenance and cost savings.

With CondoConnect, building management is made easy and convenient, saving you time and money while improving the overall living experience.
			</p>

			<h2>FamLeaf: Build and Preserve Your Family Tree</h2>
			<p>
			FamLeaf is a user-friendly app designed to help you build and preserve your family tree. With FamLeaf, you can easily create leafs for each family member, including their personal information and a memorable text highlight. You can also add other users as family members to help you build your family tree together.
			<br />
FamLeaf is customizable, allowing you to scope your family tree to any degree you prefer. As you add more leafs, FamLeaf will automatically generate a visual family tree display that can grow as much as you want. You can also share your family tree with other family members and collaborate on building your family history together.
			<br />

Whether you're interested in learning more about your family history or want to preserve it for future generations, FamLeaf is the perfect app for you. Get started today and discover the rich history of your family tree!
			</p>
			<h2>Medieval Quest: Conquer Portugal's History and Culture</h2>
			<p>
			Join the adventure and explore Portugal's rich history and culture through a series of challenges and quests that take you to ancient castles, breathtaking churches, and other points of interest. With GPS-enabled technology, you can track your progress, earn rewards, and learn fascinating local information along the way. Medieval Quest is your ultimate guide to the wonders of Portugal, brought to life in a fun and engaging way.
			</p>
			<p>
			Medieval Quest is an immersive and interactive game that takes you on a journey through Portugal's fascinating history and culture. As a player, your objective is to conquer a series of challenges that are spread across various cities and towns in the country.
<br />
The game is designed to promote tourism and culture in Portugal by showcasing its iconic landmarks, such as castles, churches, and other points of interest. Each of these landmarks is categorized based on their historical significance, and players are given a set of quests to complete for each category.
<br />

To play the game, you'll need to download the app, which is available on both iOS and Android devices. Once you've installed the app, you'll be prompted to create an account, which will allow you to track your progress and earn rewards.
<br />

To get started, you'll need to select a challenge and navigate to the starting point using the GPS-enabled map. Once you arrive at the location, you'll be given a set of tasks to complete. These tasks can include answering trivia questions, taking photos, or finding hidden objects.
<br />

As you progress through the game, you'll earn rewards in the form of points, badges, and other in-game items. These rewards can be used to unlock new challenges and access additional information about the landmarks you've visited.
<br />

Overall, Medieval Quest is an exciting and educational game that provides a unique way to explore Portugal's history and culture. Whether you're a tourist looking to experience the country's iconic landmarks or a local interested in learning more about your country's heritage, Medieval Quest is a must-play game for anyone interested in history and culture.
			</p>
			<h2> EcoPower Builder</h2>
			<p>
			The EcoPower Builder app is an innovative tool that allows users to design and build their own solar powerbank system using lithium batteries and photovoltaic panels. With the ability to monitor real-time data on input batteries and PV panel efficiency, users can easily add and remove modules as needed to meet their current energy requirements. The app also provides options for output in multiple voltages, including controlled sine wave 230V AC, making it a versatile choice for a wide range of applications. By using the EcoPower Builder app, individuals can take control of their energy needs and reduce their carbon footprint while enjoying the benefits of renewable energy. 
			</p>

			<div id="PDFgenerator">
				<PDFmaker></PDFmaker>
			</div>

			{/* <figure>
				<img src="https://assets.codepen.io/t-1/freddy-g-lhy1lY3CyLI-unsplash.jpg" alt="a smiling person in a pink hoodie, standing in front of a bright pink lighted arcade basketball game. " />
				<figcaption>Photo by Freddy G</figcaption>
			</figure> */}
			{/* <figure>
				<img src="https://assets.codepen.io/t-1/element5-digital-dwcC-OJ-bRs-unsplash.jpg" alt="three neon-lit skee ball lanes. " />
				<figcaption>Photo by Element5 Digital</figcaption>
			</figure> 
			
			<aside>
				<p>Frontend: <strong>React Next</strong> Backend: <strong> Node Express</strong> with <strong>PostgreSQL database</strong>.
				<br />Electronics: 
				</p>
			</aside>

			*/}
		</div>
	</div>
</div>

)}
