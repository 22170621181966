import '../Apps.scss'
import './WebPortfolioApp.scss'
import React from 'react'

import CurriculumV from './routes/CurriculumV/CurriculumV';
import LandingPage from './routes/LandingPage/LandingPage';
import News from './routes/News/News';
import Studio from './routes/Studio/Studio';
import LoadingToRedirect from '../../components/LoadingToRedirect';

export const WebPortfolioAppData = {
    id: "WebPortfolioApp",
    path: "/webportfolio",
    label: "Portfolio",

    routesData: [
        {
            path: "/welcome",
            type: "",
            element: <LandingPage />,
            title: "Welcome",
            navLinks: [
                {
                    ref: "aboutMe",
                    label: "About me"
                },
                {
                    ref: "interests",
                    label: "Interests"
                },
                {
                    ref: "contactMe",
                    label: "Contact me"
                },
            ]
        },
        {
            path: "/cv",
            type: "",
            element: <CurriculumV />,
            title: "CV",
            navLinks: [
                {
                    ref: "cv",
                    label: "CV"
                },
                {
                    ref: "portfolio",
                    label: "Portfolio"
                },
                {
                    ref: "projects",
                    label: "Projects"
                },
            ]
        },
        {
            path: "/news",
            type: "",
            element: <News />,
            title: "News",
            navLinks: [
                {
                    ref: "NeonScroll",
                    label: "Top"
                },
                {
                    ref: "PDFgenerator",
                    label: "PDFgenerator"
                }
            ]
        },
        {
            path: "/studio",
            type: "",
            element: <Studio />,
            title: "Studio",
            navLinks: [
                {
                    ref: "rooftop",
                    label: "Rooftop"
                },
                {
                    ref: "gallery",
                    label: "Gallery"
                },
                {
                    ref: "basement",
                    label: "Basement"
                },
            ]
        }
    ]
}
