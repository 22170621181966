import './PostCard.scss'
import React from 'react';
import Image from '../Image';
import { Link } from 'react-router-dom';

const PostCard = ({ 
    post, 
    showUpdateButton = false, 
    showDeleteButton = false,
    handleDelete = f => f,
    handleUpdate = f => f
}) => {
    const { images, content, postedBy } = post;
    return (
        <div className="PostCard">
            <main>
                <Link to={`/post/${post._id}`}>
                    <Image image={images[0]} />
                </Link>
                <Link to={`/user/${post.postedBy.username}`}>
                    <h4>@{post.postedBy.username}</h4>
                </Link>
                <hr />

                <small>{content}</small>

                <section className="buttons">
                    {showDeleteButton &&
                        <button onClick={()=>handleDelete(post._id)}
                            className='delete'
                        >Delete</button>
                    }
                    {showUpdateButton &&
                        <button onClick={()=>handleUpdate(post._id)}
                            className='update'
                        >Update</button>
                    }
                </section>
            </main>
        </div>
    );
};

export default PostCard;
