//Footer.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from './footer.module.scss'; 
import GlowButton from "../../buttons/GlowButton/GlowButton";
import { WebPortfolioAppData } from "../../../Apps/WebPortfolioApp/WebPortfolioApp";

export default function Footer() {
    const portfolioData = WebPortfolioAppData.routesData[0];
    const cvData = WebPortfolioAppData.routesData[1];


    function handleTopClick(){
        window.scrollTo(0,0);
    }

    const linksFromRouteData = (routeData) => routeData?.navLinks.map((link)=>
    <Link key={link.ref} to={{
        pathname:`${WebPortfolioAppData.path}${routeData.path}`,
        hash:link.ref
    }}>{link.label}</Link>
)

    return (
        <footer className={styles.appFooter}>
            <article>
                <h2>Portfolio</h2>
                {linksFromRouteData(portfolioData)}
            </article>
            <article>
                <h2>Curriculum</h2>
                {linksFromRouteData(cvData)}
            </article>
            <article>
                <Link onClick={handleTopClick}>
                    <figure>
                        <GlowButton />
                    </figure>
                    <figcaption>
                        All Rights Reserved <br/>
                        © {new Date().getFullYear()} jColaco.dev<br/>
                    </figcaption>
                </Link>
            </article>
        </footer>
    );
}